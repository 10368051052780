<template>
    <div class=" business-bg1 bg">
        <div class="wrapper flex-column gap3">
            <h1>Global Partnership</h1>
            <div class="flex-column gap1">
                <h3>{{$t('business.section1.businessHead1') }}</h3>
                <p>{{$t('business.section1.businessText1') }}</p>
                <p>{{$t('business.section1.businessText2') }}</p>
            </div>
            <div class="flex-column gap1">
                <h3>{{$t('business.section1.businessHead2') }}</h3>
                <p>{{$t('business.section1.businessText3') }}</p>
            </div>
            <div class="flex-column gap1">
                <h3>{{$t('business.section1.businessHead3') }}</h3>
                <p>{{$t('business.section1.businessText4') }}</p>
            </div>
            <div class="flex-column gap1">
                <h3>{{$t('business.section1.businessHead4') }}</h3>
                <p>{{$t('business.section1.businessText5') }}</p>
                <p>{{$t('business.section1.businessText6') }}</p>
            </div>
        </div>
    </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>

</style>