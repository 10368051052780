<template>
    <div class="modal-wrapper">
      <div class="wrapper flex-column gap3">
        <h1><img  @click="goBack" src="@/assets/images/icon/back-icon.png"/>Entertainer Partners</h1>
        <div class="flex-column gap1" v-if="modelDetail !=null">
            <div class="main-container flex-column gap1">
                <a data-fancybox="gallery" :href="mainImg">
                    <img class="main-img" :src="mainImg" />
                </a>
                <p>{{modelDetail[locale]['name']}}</p>
                <img src="@/assets/images/icon/insta-icon.png" class="icon"/>
            </div>
            <div class="sub-content">
              <div class="sub-container gap1">
                <a data-fancybox="gallery" v-for="(image,i) in imageUrls" :key="i" :href="image">
                <img class="sub-img" :src="image" />
                </a>
              </div>
            </div>
        </div>
      </div>
    </div>
</template>

<script setup>
import '@/assets/css/modal.css';
import '@fancyapps/ui/dist/fancybox/fancybox.css';
import '@/utils/fanyBox'
import axios from 'axios';
import { onUnmounted, ref,nextTick,onMounted  } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute,useRouter } from 'vue-router';
const {locale}  = useI18n({useScope:global})
const route = useRoute();
const router = useRouter();

const modelDetail = ref(null);
const mainImg = ref('');
const imageUrls = ref([]);
axios.get(`https://tenenm.com/admin/api/actor-detail-api.php/${route.params.id}`)
.then((res)=> {
    modelDetail.value = res.data[0]
    console.log(modelDetail.value);
    mainImg.value = modelDetail.value.main_img
    Object.keys(modelDetail.value).forEach(key => {
      if(key.slice(0,-1) == "sub_img"){
        imageUrls.value.push(modelDetail.value[key])
      }
    });
    document.body.style.overflow = 'hidden';
})

function goBack(){
  router.push('/partner').then(()=> {
    nextTick(()=> {
      const slideWrapper = document.querySelector('.slide-wrapper')
      if(slideWrapper){
        slideWrapper.scrollIntoView({behavior:'smooth'})
      }
    })
  })
}
onMounted(() => {
  // Fancybox.bind('[data-fancybox="gallery"]', {
  //   Toolbar: {
  //     display: [
  //       "zoom",
  //       "fullscreen",
  //       "download",
  //       "thumbs",
  //       "close",
  //     ],
  //   },
  //   Image: {
  //     zoom: true,
  //   },
  // });
  // Fancybox.fromSelector('[data-fancybox="gallery"]');

});
// function openFancybox() {
//   // Fancybox.show([{ src: imageUrl, type: "image" }]);
//   // Fancybox.fromSelector('[data-fancybox="gallery"]');
//   // Fancybox.fromNodes(Array.from(document.querySelectorAll('[data-fancybox="gallery"]')));
// }
onUnmounted(()=> {
  document.body.style.overflow = '';

})
</script>

<style lang="scss" scoped>

</style>