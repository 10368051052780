export default {
    about: {
        aboutText1: 'Ten Entertainmentは、創造的で革新的なコンテンツで市場をリードしています。',
        aboutText2: '私たちはさまざまなプラットフォームの企画を通じて感動と楽しさを伝え、大衆に新しい経験と記憶を与えます。',
        aboutText3: '私たちは絶え間ない挑戦でエンターテイメントの新しい地平を開いていきます。',
        aboutHead1: 'あなたのエピソードが世界中に光を放ちます。',
        aboutText4: 'Ten Entertainmentは、コンテンツの制作からグローバル流通まで、あらゆるプロセスを専門的に行います。',
        aboutText5: '創造的なアイデアを現実に実装し、幅広いネットワークを介して大衆とコミュニケーションできるプラットフォームを提供します。',
        aboutText6: 'アイデアの実装と制作',
        aboutText7: 'グローバルネットワーク保有',
        aboutText8: 'コンテンツ流通',
    },
    business: {
        section1: {
            businessHead1: 'ビジネスパートナーシップの構築',
            businessHead2: '文化・コンテンツパートナーシップ',
            businessHead3: '事業協力',
            businessHead4: '市場開拓と拡大',
            businessText1: '日本、台湾、タイ、オーストラリア、米国などグローバル企業や組織との協力を推進',
            businessText2: '契約と条件を交渉し、製品やサービス、コンテンツの供給、流通条約、共同事業開発エージェント',
            businessText3: '国家や企業との文化・コンテンツパートナーシップを構築し、共生する方案づくり',
            businessText4: '各種分野の協力を促進し、事業共有及びプロジェクトを主導してシナジー提供',
            businessText5: '新しい市場開拓と拡大のために地元のパートナーとの協力を推進し、',
            businessText6: '市場調査によるマーケティング戦略の確立、sネットワーキングなどを通じて市場参入をサポート'
        },
        section2: {
            businessHead1: 'コンテンツの企画と開発',
            businessHead2: '制作',
            businessHead3: "配信とマーケティング",
            businessText1: "新しいアイデアを発掘し、そのアイデアに基づいて様々なコンテンツを企画して開発",
            businessText2: "映像、ウェブドラマ、各種プログラム、 Webシリーズ、その他のコンテンツなど多様なジャンルを企画して開発",
            businessText3: "シナリオの作成、撮影、編集などをはじめ、監督、プロデューサー、スクリプトライター、撮影などの制作専門人材保有",
            businessText4: "制作されたコンテンツを公衆に配信し、それに伴うマーケティング活動をサポート",
            businessText5: "放送の送出とインターネットストリーミングプラットフォームを介しての配信、広告、プロモーションなどを行う"
        },
        section3: {
            businessHead1: 'プラットフォーム企画開発',
            businessHead2: "プラットフォームの運用",
            businessHead3: "コミュニケーションと対話",
            businessHead4: "コンテンツ提供",
            businessText1: "ユーザー同士対話できるデジタルプラットフォームを企画開発",
            businessText2: "ソフトウェアとアプリケーションの開発、ユーザーインターフェースとユーザーエクスペリエンスの設計、データベースの構築",
            businessText3: "プラットフォームの円滑な運営を担い、サーバーとネットワークの管理、セキュリティとデータ管理、ユーザーサポートとメンテナンスなどの業務を遂行",
            businessText4: "ユーザー間のコミュニケーションを促進・対話し,",
            businessText5: "掲示板を含むチャット、コメント、共有などでプラットフォームの活性化をサポート",
            businessText6: "グローバル提携を通じて様々な国のプラットフォームにコンテンツを供給・提供し、",
            businessText7: "各種映像、オーディオ、ゲーム、e‐コマースなど、さまざまな形式のコンテンツを製作供給",
        },
        section4: {
            businessHead1: "MCN(Multi-Channel Network)運用",
            businessHead2: "エンターテインメント",
            businessText1: "多数のクリエイターやストリーマー、チャンネルを代表してコンテンツを管理し、宣伝を担当",
            businessText2: "複数のクリエイターとチャンネルをパートナーにし、クリエイター専用のコンテンツを製作、YouTubeなどのプラットフォームでの広告収益管理などをサポート、クリエイターと一緒にチャンネルをより効果的に管理し、成長に貢献",
            businessText3: "これによりクリエーターと共にチャンネルをより効果的に管理、成長に寄与",
            businessText4: "独自にモデルとホストを育成し、コンテンツを企画、制作し",
            businessText5: "様々なプラットフォームに流通",
            businessText6: "映像、ウェブドラマ、音楽、ゲームなど様々な形態のコンテンツを含む",
            businessText7: "また、このコンテンツを広報し、視聴者との相互コミュニケーションを支援",
        },
    },
    partner: {
        section1: {
            partnerHead1: "テンエンターテインメントパートナー",
            partnerText1: " 多数のパートナーが Ten entertainmentをパートナーに選択しています。"
        },
        section2: {
            partnerHead1: "Ten entertainment セレブリティパートナー",
            partnerText1: "多数のエンターテイナーがTen entertainment と共にしています。"
        },
    },
    contact: {
        contactHead1: "お問い合わせ",
        partnerText1: "ご不明な点がございましたら、お問い合わせください",
        brand: "お名前（会社＆ブランド)",
        name: "担当者",
        number: "お問い合わせ",
        email: "Eメール",
        emailSelect: "直接入力",
        contactText: "お問い合わせ内容",
        checkRadio: "個人情報の収集及び利用同意",
        contactButton: "お問い合わせ",
        emailSuccess: "Eメールが正常に送信されました。",
        emailFail: "Eメールの送信に失敗。"
    },
    footer: {
        address: "東京都江南区宣陵路107ギル13 ジョンジンビルディング 2階",
        email: "Eメール",
        businessNumber: "事業者登録番号",
        copyright: "ALL RIGHT RESERVED"
    }
}