import MainView from '@/views/MainView.vue';
import AboutView from '@/views/AboutView.vue';
import BusinessView from '@/views/BusinessView.vue';
import PartnerView from '@/views/PartnerView.vue';
import ContactView from '@/views/ContactView.vue';
import NotFound from '@/components/NotFound.vue';
import PartnerModal from '@/components/partner/PartnerModal.vue';
const routes = [
    {
        path:"/",
        component:MainView
    },
    {
        path:"/about",
        component:AboutView
    },
    {
        path:"/business",
        component:BusinessView
    },
    {
        path:"/partner",
        component:PartnerView
    },
    {
        path:"/partner/:id",
        component:PartnerModal
    },
    {
        path:"/contact",
        component:ContactView
    },
    {
        path:"/404",
        component:NotFound
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: "/404",
    },
]

export default routes;