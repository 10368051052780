<template>
    <div class="about-bg1 bg">
        <div class="about-container flex-column wrapper" v-show="!isMobile">
            <div class="flex-column">
                <h1>Try Everything New!</h1>
                <p>{{$t('about.aboutText1') }}</p>
                <p>{{$t('about.aboutText2') }}</p>
                <p>{{$t('about.aboutText3') }}</p>
            </div>
            <div class="flex-column">
                <h2>{{$t('about.aboutHead1') }}</h2>
                <p>{{$t('about.aboutText4') }}</p>
                <p>{{$t('about.aboutText5') }}</p>
            </div>
            <div class="flex about-feature gap2">
                <div class="flex-column gap3">
                    <img src="@/assets/images/about/about-img1.png"/>
                    <p>{{$t('about.aboutText6') }}</p>
                </div>
                <div class="flex-column gap3">
                    <img src="@/assets/images/about/about-img2.png"/>
                    <p>{{$t('about.aboutText7') }}</p>
                </div>
                <div class="flex-column gap3">
                    <img src="@/assets/images/about/about-img3.png"/>
                    <p>{{$t('about.aboutText8') }}</p>
                </div>
            </div>
        </div>
        <div class="about-container mb">
            <div class="mb-bg">
                <div class="flex-column absolute-center first-content w-90">
                    <h1>Try Everything New!</h1>
                    <p>{{$t('about.aboutText1') }}</p>
                    <p>{{$t('about.aboutText2') }}</p>
                    <p>{{$t('about.aboutText3') }}</p>
                </div>
            </div>
            <div class="second-content">
                <div class="flex-column w-90">
                    <h2>{{$t('about.aboutHead1') }}</h2>
                    <p>{{$t('about.aboutText4') }}</p>
                    <p>{{$t('about.aboutText5') }}</p>
                </div>
                <div class="flex about-feature gap1 w-90">
                    <div class="flex-column gap3">
                        <img src="@/assets/images/about/about-img1-mb.png"/>
                        <p>{{$t('about.aboutText6') }}</p>
                    </div>
                    <div class="flex-column gap3">
                        <img src="@/assets/images/about/about-img2-mb.png"/>
                        <p>{{$t('about.aboutText7') }}</p>
                    </div>
                    <div class="flex-column gap3">
                        <img src="@/assets/images/about/about-img3-mb.png"/>
                        <p>{{$t('about.aboutText8') }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref,onMounted, onUnmounted} from 'vue';
const isMobile = ref(false);
onMounted(() => {
    window.innerWidth <= 576 ? isMobile.value = true : isMobile.value = false
    window.addEventListener('resize', checkSize )
    console.log(isMobile.value);
})
onUnmounted(()=>{
    window.removeEventListener('resize',checkSize)
})

function checkSize(){
    window.innerWidth <= 576 ? isMobile.value = true : isMobile.value = false
    // console.log(isMobile.value);
}
</script>

<style lang="scss" scoped>

</style>