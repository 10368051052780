<template>
    <div class="bg partner1-bg">
        <div class="wrapper flex-column gap3">
            <h1>Partners Company</h1>
            <div class="flex-column gap1">
                <h3>{{$t('partner.section1.partnerHead1') }}</h3>
                <p>{{$t('partner.section1.partnerText1') }}</p>
            </div>
            <div class="flex gap1 justify-center">
                <div class="partner-img-container">
                    <!-- <img :src="require(`@/assets/images/partner/business-partner-img${a}.png`)" v-for="a in 9" :key="a"/> -->
                    <img :src="require(`@/assets/images/partner/business-partner-img1.png`)"/>
                    <img :src="require(`@/assets/images/partner/business-partner-img2.png`)"/>
                    <img :src="require(`@/assets/images/partner/business-partner-img7.png`)"/>
                    <img :src="require(`@/assets/images/partner/business-partner-img8.png`)"/>
                    <img :src="require(`@/assets/images/partner/business-partner-img6.png`)"/>
                    <!-- <img src="@/assets/images/partner/business-partner-img-mb.png" class="mb"/> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>

</style>