<template>
  <div class="bg">
    <div class="wrapper flex-column gap3">
      <h1>Entertainer Partners</h1>
      <div class="flex-column gap1">
        <h3>{{$t('partner.section2.partnerHead1') }}</h3>
        <p>{{$t('partner.section2.partnerText1') }}</p>
      </div>
    </div>
    <div class="slide-wrapper">
      <img src="@/assets/images/icon/arrow-left.png" class="prev-icon swiper-button-prev" v-show="isBegin"/>
        <swiper
          v-if="modelList.length > 0"
          ref="mySwiper"
          :modules="modules"
          :slides-per-view="2"
          :space-between="10"
          :mousewheel="true"
          :navigation="{nextEl:'.swiper-button-next',prevEl:'.swiper-button-prev'}"
          @swiper="onSwiper"
          @slideChange="onSlideChange"
          :loop="false"
          :grid="{ rows: 3, fill: 'row' }"
          :breakpoints="{
            768: { // 768 이상
              slidesPerView: 2,
              spaceBetween: 10,
              grid: { rows: 2, fill: 'row' }
            },
            1024: { // 1024 이상
              slidesPerView: modelList.length >= 3 ? 3 : 4,
              grid: { rows: modelList.length >= 3 ? 2 : 1 , fill: 'row' }
            },
            1025: { // 1025 이상
              slidesPerView: modelList.length >= 4 ? 4 : 4,
              grid: { rows: modelList.length >= 4 ? 2 : 1 , fill: 'row' }
            },
            1441: { // 1440 이상
              slidesPerView: modelList.length >= 6 ? 6 : 6,
              grid: { rows: modelList.length >= 6 ? 2 : 1 , fill: 'row' }
            }
          }"
        >
          <swiper-slide v-for="(model,a) in modelList" :key="a">
            <div class="partner-img" @click="openModal(model.id)">
              <img :src="`${model.main_img}`"/>
              <p>{{model[locale]}}</p>
            </div>
          </swiper-slide>
        </swiper>
        <img src="@/assets/images/icon/arrow-right.png" class="next-icon swiper-button-next" v-show="isEnd" ref="nextEl"/>
    </div>
    <!-- <PartnerModal v-if="isModalOpen" :data="modelDetail" @close="closeModal" /> -->
  </div>
</template>

<script setup>
// import PartnerModal from './PartnerModal.vue';
import { ref,onMounted, onUnmounted } from 'vue';
import { Grid, Mousewheel,Navigation  } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import 'swiper/css';
import 'swiper/css/grid';
import axios from 'axios';
const {locale}  = useI18n({useScope:global})
const router = useRouter();

// Swiper 인스턴스를 참조할 수 있는 ref 생성
const mySwiper = ref(null);
// const isModalOpen = ref(false);
// const modelDetail = ref(null);
const modelList = ref([]);
axios.get('https://tenenm.com/admin/api/actor-list-api.php').then((res)=> {
    modelList.value = res.data;
    console.log('res',res.data);
    console.log('modelList',modelList.value);
})
onMounted(()=> {})
onUnmounted(()=> {
  document.body.style.overflow = '';
})
const isBegin = ref(false);
const isEnd = ref(false);
const nextEl = ref(null)
const onSwiper = (swiper) => {
  console.log('modelList.value.length',modelList.value.length);
  if(modelList.value.length > 0){
    mySwiper.value = swiper;
    nextEl.value = swiper.nextEl
    console.log('nextEl',nextEl.value);
    console.log('Swiper instance:', swiper);
    updateSlideSize(swiper);
    window.addEventListener('resize', () => updateSlideSize(swiper));
    isBegin.value = !swiper.isBeginning
    isEnd.value = !swiper.isEnd
  }
};
const updateSlideSize = (swiper) => {
  const windowWidth = window.innerWidth;
  console.log('windowWidth',windowWidth);
  const slides = swiper.slides;
  const slideWrapper = document.querySelector('.slide-wrapper');

  if (slideWrapper) {
    const width = slides[0].clientWidth;
    slides.forEach((slide) => {
      slide.style.height = `${width}px`; // 1:1 비율로 설정
    });
    console.log('slides.length',slides.length);
    if(!isBegin.value && !isEnd.value){ // 1줄일때
      if(windowWidth >1440){
        slides.length >= 6 ? slideWrapper.style.height = `${width*2 + 5}px` : slideWrapper.style.height = `${width + 5}px`
      }else if(windowWidth >1024){
        slides.length >= 4 ? slideWrapper.style.height = `${width*2 + 5}px` : slideWrapper.style.height = `${width + 5}px`
      }else if(windowWidth >=1024){
        slides.length >= 3 ? slideWrapper.style.height = `${width*2 + 5}px` : slideWrapper.style.height = `${width + 5}px`
      }else if(windowWidth >=768){
        slides.length >= 3 ? slideWrapper.style.height = `${width*2 + 5}px` : slideWrapper.style.height = `${width + 5}px`
      }else{
        slideWrapper.style.height = `${width *3 + 5}px`
      }
      // windowWidth > 768 ? slideWrapper.style.height = `${width + 5}px` : slideWrapper.style.height = `${width*2  + 5}px`// slide-wrapper 높이 설정
    } else{
      windowWidth >= 768 ? slideWrapper.style.height = `${width * 2 + 5}px` : slideWrapper.style.height = `${width *3 + 5}px`// slide-wrapper 높이 설정
    }
  }
};

const onSlideChange = (swiper) => {
  console.log('Slide change');
  isBegin.value = !swiper.isBeginning
  isEnd.value = !swiper.isEnd
};

// const slideNext = (swiper) => {
//   if (mySwiper.value) {
//     console.log('mySwiper.value', mySwiper.value);
//     swiper.slideNext(); // swiper 인스턴스를 통해 메소드 호출
//   }
// };

// const slidePrev = () => {
//   if (mySwiper.value) {
//     mySwiper.value.slidePrev();
//   }
// };

const modules = [Grid, Mousewheel,Navigation ];

const openModal = (id) => {
  router.push({path:`/partner/${id}`})
  // axios.get(`http://43.200.137.231/admin/api/actor-detail-api.php/${id}`)
  // .then((res)=> {
  //   modelDetail.value = res.data;
  //   isModalOpen.value = true;
  //   // document.body.style.overflow = 'hidden';
  // })
};

// const closeModal = () => {
//   isModalOpen.value = false;
//   document.body.style.overflow = '';
// };
</script>

<style scoped>

</style>
