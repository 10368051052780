<template>
    <div class="header-wrapper">
        <div class='header-container flex'>
            <div class="logo-container">
                <img src="@/assets/images/header/logo-web.png" @click="navigateTo('')" id="main"/>
            </div>
            <div class="header-menu flex">
                <p :class="{ 'color-accent': activeTab === 'about' }" @click="navigateTo('about')">ABOUT</p>
                <p :class="{ 'color-accent': activeTab === 'business' }" @click="navigateTo('business')">BUSINESS</p>
                <p :class="{ 'color-accent': activeTab === 'partner' }" @click="navigateTo('partner')">PARTNER</p>
                <p :class="{ 'color-accent': activeTab === 'contact' }" @click="navigateTo('contact')">CONTACT</p>
            </div>
            <div class="lang-container web" @click="selectLang">
                <button class="label web">KOR</button>
                <ul class="optionList" @click="selectLang">
                    <li class="optionItem" data-value="ko">KOR</li>
                    <li class="optionItem" data-value="en">ENG</li>
                    <li class="optionItem" data-value="ja">JP</li>
                    <li class="optionItem" data-value="zh">CN</li>
                </ul>
            </div>
        </div>
        <div class="header-container flex mb">
            <div class="logo-container">
                <img src="@/assets/images/header/logo-web-black.png" @click="navigateTo('')" id="main"/>
            </div>
            <div class="lang-container mb" @click="selectLang">
                <button class="label mb">KOR</button>
                <ul class="optionList" @click="selectLang" >
                    <li class="optionItem" data-value="ko">KOR</li>
                    <li class="optionItem" data-value="en">ENG</li>
                    <li class="optionItem" data-value="ja">JP</li>
                    <li class="optionItem" data-value="zh">CN</li>
                </ul>
            </div>
            <img src="@/assets/images/header/menu-bar.png" class="menu-bar" @click="toggleMenu"/>
        </div>
    </div>
    <Transition>
        <div class="header-menu mb" v-show="isOpen">
            <div>
                <img src="@/assets/images/header/logo-mb-black.png"/>
            </div>
            <div class="flex-column menu-list">
                <p :class="{ 'color-accent': activeTab === 'about' }" @click="navigateTo('about')">ABOUT</p>
                <p :class="{ 'color-accent': activeTab === 'business' }" @click="navigateTo('business')">BUSINESS</p>
                <p :class="{ 'color-accent': activeTab === 'partner' }" @click="navigateTo('partner')">PARTNER</p>
                <p :class="{ 'color-accent': activeTab === 'contact' }" @click="navigateTo('contact')">CONTACT</p>
            </div>
        </div>
    </Transition>
</template>

<script setup>
import '@/assets/css/header.css';
// import { watch } from 'vue';
import { ref } from 'vue';
import { computed,onMounted } from 'vue';
import { useRoute,useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n'
const { locale } = useI18n({ useScope: 'global' })
const router = useRouter();
const route = useRoute();
const windowWidth = ref();
windowWidth.value = window.innerWidth

const activeTab = computed(() => {
  switch (route.path) {
    case '/about':
      return 'about';
    case '/business':
      return 'business';
    case '/partner':
      return 'partner';
    case '/contact':
      return 'contact';
    default:
      return '';
  }
});
onMounted(()=> {
    window.addEventListener('resize',resizeWindow);
    const langContainer = windowWidth.value > 576 ? document.querySelector('.lang-container.web') : document.querySelector('.lang-container.mb');
    const label = windowWidth.value > 576 ? document.querySelector('.label.web') : document.querySelector('.label.mb')
    document.addEventListener('click',function(event){
        const menuBar = document.querySelector('.menu-bar')
        if(!menuBar.contains(event.target)){
            isOpen.value = false
        }
        if (!langContainer.contains(event.target)) {
            langContainer.classList.remove('active');
        }
    })
    let selectedLang = localStorage.getItem('lang');
    console.log('selectedLang',selectedLang);
    if(!selectedLang){
        label.innerHTML = 'KOR'
        localStorage.setItem('lang','ko')
        locale.value = 'ko'
    }else if(selectedLang == 'ko'){
        label.innerHTML = 'KOR'
        locale.value = selectedLang
    }else if(selectedLang == 'en'){
        label.innerHTML = 'ENG'
        locale.value = selectedLang
    }else if(selectedLang == 'ja'){
        label.innerHTML = 'JP'
        locale.value = selectedLang
    }else if(selectedLang == 'zh'){
        label.innerHTML = 'CN'
        locale.value = selectedLang
    }
})
const isOpen = ref(false)
function toggleMenu(){
    isOpen.value = !isOpen.value
}

function navigateTo(tab) {
    router.push(`/${tab}`);
    isOpen.value = false
}

function selectLang(){

    const langContainer = windowWidth.value > 576 ? document.querySelector('.lang-container.web') : document.querySelector('.lang-container.mb')
    if(langContainer.classList.contains('active')){
        langContainer.classList.remove('active')
    }else {
        langContainer.classList.add('active')
    }
    const label = windowWidth.value > 576 ? document.querySelector('.label.web') : document.querySelector('.label.mb')
    const options = document.querySelectorAll('.optionItem');
    const handleSelect = function(item) {
        label.innerHTML = item.textContent;
        label.parentNode.classList.remove('active');
        // select val
        localStorage.setItem('lang',item.getAttribute('data-value'))
        locale.value =item.getAttribute('data-value')
    }
    options.forEach(function(option){
        option.addEventListener('click', function(){handleSelect(option)})
    })
}

function resizeWindow(){
    windowWidth.value = window.innerWidth
    // console.log(windowWidth.value);
}

</script>

<style lang="scss" scoped>
.v-enter-active {
    transform: translate(0px,-50%);
    transition: all .5s;
}
.v-leave-active {
    transform: translate(0px,-50%);
    transition: all .5s;
}
.v-enter-from {
  transform: translate(200px,-50%)
}

.v-leave-to {
  transform: translate(200px,-50%)
}
</style>