<template>
    <div class="business-bg3 bg">
        <div class="wrapper flex-column gap3">
            <h1>Platform Service</h1>
            <div class="flex-column gap1">
                <h3>{{$t('business.section3.businessHead1') }}</h3>
                <p>{{$t('business.section3.businessText1') }}</p>
                <p>{{$t('business.section3.businessText2') }}</p>
            </div>
            <div class="flex-column gap1">
                <h3>{{$t('business.section3.businessHead2') }}</h3>
                <p>{{$t('business.section3.businessText3') }}</p>
            </div>
            <div class="flex-column gap1">
                <h3>{{$t('business.section3.businessHead3') }}</h3>
                <p>{{$t('business.section3.businessText4') }}</p>
                <p>{{$t('business.section3.businessText5') }}</p>
            </div>
            <div class="flex-column gap1">
                <h3>{{$t('business.section3.businessHead4') }}</h3>
                <p>{{$t('business.section3.businessText6') }}</p>
                <p>{{$t('business.section3.businessText7') }}</p>
            </div>
            <img class="mb" src="@/assets/images/business/business-platform-img-mb.png"/>
        </div>
    </div>
</template>

<script setup>
import { onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n'
const { locale } = useI18n({ useScope: 'global' })
onMounted(()=> {
    addLangClass()
})
function addLangClass(oldVal){
    let localeEl = document.querySelectorAll('.business-bg3 p');
    localeEl.forEach((el)=> {
        if(el.classList.contains(oldVal)){
            el.classList.remove(oldVal)
        }
        el.classList.add(locale.value)
    })
}
watch(locale, (newVal,oldVal)=> {
    addLangClass(oldVal)
})
</script>

<style scoped>
p.ja,p.en {
    width: 50%;
    word-break:break-word;
  }
@media screen and (max-width: 576px) {
    p.ja, p.en {
        width: 100%;
    }
}
</style>