export default {
    about: {
        aboutText1: 'Ten Entertainment는 창의적이고 혁신적인 콘텐츠로 시장을 선도합니다',
        aboutText2: '우리는 다양한 플랫폼 기획을 통해 감동과 재미를 전달하며, 대중에게 새로운 경험과 기억을 선사합니다',
        aboutText3: '우리는 끊임없는 도전으로 엔터테인먼트의 새로운 지평을 열어갑니다',
        aboutHead1: '당신의 이야기가 전세계로 빛을 발할 것입니다',
        aboutText4: 'Ten Entertainment는 콘텐츠의 제작부터 글로벌 유통까지 모든 과정을 전문적으로 진행합니다',
        aboutText5: '창의적인 아이디어를 현실로 구현하고, 광범위한 네트워크를 통해 대중과 소통할 수 있는 플랫폼을 제공합니다',
        aboutText6: '아이디어 구현 및 제작',
        aboutText7: '글로벌 네트워크 보유',
        aboutText8: '콘텐츠 유통',
    },
    business: {
        section1: {
            businessHead1: '비즈니스 파트너십 구축',
            businessHead2: '문화 및 콘텐츠 파트너십',
            businessHead3: '사업 협력',
            businessHead4: '시장 개척 및 확장',
            businessText1: '일본, 대만, 태국, 호주, 미국 등 글로벌 기업이나 조직과의 협력을 추진',
            businessText2: '계약 및 조건을 협상하여 제품이나 서비스, 콘텐츠의 공급, 유통 협약, 공동 사업 개발 Agent',
            businessText3: '국가나 기업과의 문화 및 콘텐츠 파트너십을 구축하여 상생하는 방안 마련',
            businessText4: '각종 분야의 협력을 촉진하고, 사업 공유 및 프로젝트를 주도하여 시너지 제공',
            businessText5: '새로운 시장 개척 및 확장을 위한 현지 파트너와의 협력을 추진하고',
            businessText6: '시장 조사, 마케팅 전략 수립, 네트워킹 등을 통해 시장 진입을 지원'
        },
        section2: {
            businessHead1: '콘텐츠 기획 및 개발',
            businessHead2: '제작',
            businessHead3: "배급 및 마케팅",
            businessText1: "새로운 아이디어를 발굴하고, 그 아이디어를 바탕으로 다양한 콘텐츠를 기획하고 개발",
            businessText2: "영상, 웹드라마, 각종 프로그램, 웹 시리즈, 그외 콘텐츠 등 다양한 장르를 기획하여 개발",
            businessText3: "시나리오 작성, 촬영, 편집 등을 포함하여 감독, 프로듀서, 스크립트 라이터, 촬영 등의 제작전문인력 보유",
            businessText4: "제작된 콘텐츠를 대중에게 전달하기 위해 배급 및 마케팅 활동을 지원",
            businessText5: "방송 송출 및 인터넷 스트리밍 플랫폼을 통한 배급, 광고 및 프로모션 등 진행"
        },
        section3: {
            businessHead1: '플랫폼 기획 및 개발',
            businessHead2: "플랫폼 운영",
            businessHead3: "커뮤니케이션 및 상호작용",
            businessHead4: "콘텐츠 제공",
            businessText1: "사용자들이 상호작용할 수 있는 디지털 플랫폼을 기획 개발",
            businessText2: "소프트웨어 및 애플리케이션 개발 사용자 인터페이스 및 사용자 경험 설계, 데이터베이스 구축",
            businessText3: "플랫폼의 원활한 운영을 담당하며 서버 및 네트워크 관리, 보안 및 데이터 관리, 사용자 지원 및 유지보수 등의 업무를 수행",
            businessText4: "사용자들 간의 커뮤니케이션을 촉진하고 상호작용하여",
            businessText5: "게시판을 비롯한 채팅, 댓글, 공유 등으로 플랫폼 활성화 지원",
            businessText6: "글로벌 제휴를 통하여 여러 나라의 플랫폼에서 콘텐츠를 공급",
            businessText7: "제공받아 각종 영상, 오디오, 게임, 전자상거래 등의 다양한 형태의 콘텐츠 이용 가능",
        },
        section4: {
            businessHead1: "MCN (Multi-Channel Network) 운영",
            businessHead2: "엔터테인먼트",
            businessText1: "다수의 크리에이터나 스트리머, 채널들을 대표하여 그들의 콘텐츠를 관리하고 홍보를 담당",
            businessText2: "여러 크리에이터와 채널을 파트너로 삼아 크리에이터 전용 콘텐츠 제작, 마케팅, 유튜브 및 기타 플랫폼에서의 광고 수익 관리 등을 지원",
            businessText3: "이를 통해 크리에이터와 함께 채널을 더욱 효과적으로 관리, 성장에 기여",
            businessText4: "자체적으로 모델 및 호스트 육성. 콘텐츠를 기획, 제작하고",
            businessText5: "이를 다양한 플랫폼을 통해 유통하며",
            businessText6: "영상, 웹 드라마, 음악, 게임 등 다양한 형태의 콘텐츠 포함",
            businessText7: "또한, 이 콘텐츠를 홍보하고 시청자와의 상호 커뮤니케이션을 지원",
        },
    },
    partner: {
        section1: {
            partnerHead1: "텐엔터테인먼트 파트너사",
            partnerText1: "다수의 파트너사가 텐엔터테인먼트를 선택했습니다"
        },
        section2: {
            partnerHead1: "텐엔터테인먼트 파트너스",
            partnerText1: "다수의 엔터테이너가 텐엔터테인먼트와 함께합니다"
        },
    },
    contact: {
        contactHead1: "문의하기",
        partnerText1: "궁금한 내용을 문의하여 주시기 바랍니다",
        brand: "이름(브랜드)",
        name: "담당자명",
        number: "연락처",
        email: "이메일",
        emailSelect: "직접입력",
        contactText: "문의사항",
        checkRadio: "개인정보 수집 및 이용 동의",
        contactButton: "문의하기",
        emailSuccess: "이메일이 성공적으로 발송되었습니다.",
        emailFail: "이메일 발송 실패."
    },
    footer: {
        address: "서울시 강남구 선릉로 107길 13 정진빌딩 2층",
        email: "E-Mail",
        businessNumber: "사업자등록번호",
        copyright: "ALL RIGHT RESERVED"
    }
}