import { Fancybox } from "@fancyapps/ui";
import '@fancyapps/ui/dist/fancybox/fancybox.css';

Fancybox.bind('[data-fancybox="gallery"]', {
    autoStart: true,
    fitToView : true,
    Images: {
        // Disable animation from/to thumbnail on start/close
        zoom: false,
      },Toolbar: {
        display: {
          left: [],
          middle: ['infobar'],
          right: ['thumbs','close'],
        },
      },
    // Your custom options for a specific gallery
});