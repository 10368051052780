export default {
    about: {
        aboutText1: 'Ten Entertainment 以独具特色的创意内容引领市场。',
        aboutText2: '我们通过多样化的平台策划，传递感动和乐趣，给大众带来新的体验和记忆。',
        aboutText3: '展望未来，我们也将会不断地进行挑战和创新，去探索，去开创娱乐的新篇章。',
        aboutHead1: '您的故事将会在世界舞台上大放异彩。',
        aboutText4: 'Ten Entertainment 从内容制作到全球推送，每个流程都有对应的专业服务内容。',
        aboutText5: '我们为大家提供的是一个将创意想法变成现实，以及与公众交流沟通的网络平台。',
        aboutText6: '创意具现与制作',
        aboutText7: '全球网络与',
        aboutText8: '文化内容传播',
    },
    business: {
        section1: {
            businessHead1: '建立商业伙伴关系',
            businessHead2: '建立文化内容产业伙伴关系',
            businessHead3: '项目合作',
            businessHead4: '市场开发与拓展',
            businessText1: '推进与日本、台湾、泰国、澳大利亚、美国等跨国企业或组织的合作。 ',
            businessText2: '通过协商达成合作，进行文化产品或服务供给、文化内容产出、文化传播、共同进行项目开发等合作项目。',
            businessText3: '与国家或企业构建文化内容产业伙伴关系从而实现共赢。',
            businessText4: '促进各领域的合作，进行项目共享并主导项目开发，形成协同效应。',
            businessText5: '为开发并拓展新市场，需加强与当地企业的合作，',
            businessText6: '通过市场调查、制定营销战略、网络化管理等方式为进军新市场提供帮助'
        },
        section2: {
            businessHead1: '内容企划及开发',
            businessHead2: '制作',
            businessHead3: "播出及营销",
            businessText1: "发掘新的创意, 并以之为基础进行企划与多样化开发。",
            businessText2: "企划开发视频、网剧、各类节目、各种系列及其他内容等多种体裁。",
            businessText3: "形成不仅具有剧本编写、拍摄、剪辑等内容制作的能力，还拥有导演、制片人、编剧、摄影等专业制作人才的团队。",
            businessText4: "为了将制作的内容传达给大众, 进行播出与营销项目的策划支持。",
            businessText5: "主要通过电视台及网络流媒体平台进行播出，同时通过广告及网络宣传进行营销。"
        },
        section3: {
            businessHead1: '平台规划开发',
            businessHead2: "平台运营",
            businessHead3: "沟通与互动",
            businessHead4: "内容提供",
            businessText1: "策划并开发用户可互动的数字平台",
            businessText2: "软件及应用开发，用户界面与用户体验设计，构建数据库。",
            businessText3: "负责平台的流畅运行，进行服务器及网络管理、安全与数据管理、用户支持与维护等工作。",
            businessText4: "利用聊天、评论、分享等功能，",
            businessText5: "促进用户之间的沟通和互动，提升平台活跃度",
            businessText6: "通过全球合作，在多国平台上提供和接收内容。",
            businessText7: "提供多种类型的内容，如视频、音频、游戏、电子商务等",
        },
        section4: {
            businessHead1: "MCN (多频道网络) 运营",
            businessHead2: "娱乐",
            businessText1: "与多个创作者和频道达成合作，负责其内容管理和推广。",
            businessText2: "提供市场营销、定制频道内容、YouTube及其他平台的广告收益管理等服务。",
            businessText3: "并通过这些服务内容，与创作者共同更有效地管理和发展频道",
            businessText4: "自主培养博主和主播，策划并制作内容，",
            businessText5: "再将其发布到各大平台",
            businessText6: "制作内容包括视频、网剧、音乐、游戏等多种形式。",
            businessText7: "同时，进行内容推广，促进用户互动。",
        },
    },
    partner: {
        section1: {
            partnerHead1: "Ten Entertainment合作伙伴公司",
            partnerText1: "Ten Entertainment合作伙伴的最佳选择，期待您的信任与选择！"
        },
        section2: {
            partnerHead1: "Ten Entertainment的名人合作伙伴",
            partnerText1: "多数演艺人都选择了与Ten Entertainment携手共进，期待您的加入！"
        },
    },
    contact: {
        contactHead1: "咨询",
        partnerText1: "如有疑問請諮詢",
        brand: "姓名（公司&品牌）",
        name: "负责人",
        number: "联系方式",
        email: "电子邮件",
        emailSelect: "直接輸入",
        contactText: "咨询事项",
        checkRadio: "个人信息收集与使用同意",
        contactButton: "提交",
        emailSuccess: "邮件已发送成功。",
        emailFail: "电子邮件发送失败。"
    },
    footer: {
        address: "首尔市江南区宣陵路107街13号 正进大厦 2楼",
        email: "电子邮件",
        businessNumber: "營業執照號",
        copyright: "ALL RIGHT RESERVED"
    }
}