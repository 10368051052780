<template>
    <div class="business-bg2 bg">
        <div class="wrapper flex-column gap3 bg">
            <h1>Production</h1>
            <div class="flex-column gap1">
                <h3>{{ $t('business.section2.businessHead1') }}</h3>
                <p>{{ $t('business.section2.businessText1') }}</p>
                <p>{{ $t('business.section2.businessText2') }}</p>
            </div>
            <div class="flex-column gap1">
                <h3>{{ $t('business.section2.businessHead2') }}</h3>
                <p>{{ $t('business.section2.businessText3') }}</p>
            </div>
            <div class="flex-column gap1">
                <h3>{{ $t('business.section2.businessHead3') }}</h3>
                <p>{{ $t('business.section2.businessText4') }}</p>
                <p>{{ $t('business.section2.businessText5') }}</p>
            </div>
            <img class="mb" src="@/assets/images/business/business-prod-img-mb.png" />
        </div>
        <div class="business-slider">
            <div class="slider-top flex gap1">
                <!-- <div class="image-container">1</div>
                <div class="image-container">2</div>
                <div class="image-container">3</div>
                <div class="image-container">4</div>
                <div class="image-container">5</div>
                <div class="image-container">6</div>
                <div class="image-container">7</div>
                <div class="image-container">8</div>
                <div class="image-container">9</div> -->
            </div>
            <div class="slider-bottom flex gap1" v-show="!isMobile">
                <!-- <div class="image-container">1</div>
                <div class="image-container">2</div>
                <div class="image-container">3</div>
                <div class="image-container">4</div>
                <div class="image-container">5</div>
                <div class="image-container">6</div>
                <div class="image-container">7</div>
                <div class="image-container">8</div>
                <div class="image-container">9</div> -->
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch } from 'vue';
import { useI18n } from 'vue-i18n'
const { locale } = useI18n({ useScope: 'global' })
import axios from 'axios';
const isMobile = ref(false);
const postData = ref([]);
axios.get('https://tenenm.com/admin/api/show-list-api.php').then((res) => {
    postData.value = res.data
    console.log(postData.value);
    // setupSlider();
})
onMounted(() => {
    window.innerWidth <= 576 ? isMobile.value = true : isMobile.value = false
    window.addEventListener('resize', checkSize)
    console.log(isMobile.value);
    addLangClass()
});
onUnmounted(() => {
    window.removeEventListener('resize', checkSize)
})
// function setupSlider() {
//     const topContainer = document.querySelector('.business-slider .slider-top');
//     // const topChildren = Array.from(topContainer.children); // Convert NodeList to array

//     // topChildren.forEach(child => {
//     //     const cloneChild = child.cloneNode(true);
//     //     topContainer.appendChild(cloneChild);
//     // });

//     const bottomContainer = document.querySelector('.business-slider .slider-bottom');
//     // const bottomChildren = Array.from(bottomContainer.children); // Convert NodeList to array

//     // bottomChildren.forEach(child => {
//     //     const cloneChild = child.cloneNode(true);
//     //     bottomContainer.appendChild(cloneChild);
//     // });
//     if(postData.value.length > 0){
//         for(let i=0; i<postData.value.length;i++){
//             if(window.innerWidth > 576){
//                 if(i <=Math.round(postData.value.length / 2 )){
//                     let childContent = document.createElement('div')
//                     childContent.className = 'image-container';
//                     // childContent.innerHTML = i
//                     childContent.style.backgroundImage = `url(${postData.value[i].main_img})`
//                     topContainer.appendChild(childContent)
//                 }else{
//                     let childContent = document.createElement('div')
//                     childContent.className = 'image-container';
//                     childContent.innerHTML = i
//                     bottomContainer.appendChild(childContent)
//                 }
//             }else{
//                 let childContent = document.createElement('div')
//                 childContent.className = 'image-container';
//                 // childContent.innerHTML = i
//                 childContent.style.backgroundImage = `url(${postData.value[i].main_img})`
//                 topContainer.appendChild(childContent)
//             }
//         }
//     }
// }
function checkSize() {
    window.innerWidth <= 576 ? isMobile.value = true : isMobile.value = false
    // console.log(isMobile.value);
}

function addLangClass(oldVal) {
    let localeEl = document.querySelectorAll('.business-bg2 p');
    localeEl.forEach((el) => {
        if (el.classList.contains(oldVal)) {
            el.classList.remove(oldVal)
        }
        el.classList.add(locale.value)
    })
}
watch(locale, (newVal, oldVal) => {
    addLangClass(oldVal)
})
</script>
<style scoped>
p.ja,
p.en {
    width: 50%;
    word-break: break-word;
}

@media screen and (max-width: 576px) {

    p.ja,
    p.en {
        width: 100%;
    }
}
</style>