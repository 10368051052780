<template>
    <div class="main1-bg bg">
        <!-- <img src="@/assets/images/main/main-bg1.png" class="bg-img"/>
        <img src="@/assets/images/main/test.png" class="bg-img mb absolute-center"/> -->
        <Transition name="first">
            <img src="@/assets/images/main/main-logo.png" class="absolute-center" v-show="firstShow"/>
        </Transition>
        <Transition name="second">
            <div class="absolute-center text-container" v-show="secondShow">
                <p class="color-accent">Everyone’s Dreamlike Reality</p>
                <h1>Try Everything New</h1>
            </div>
        </Transition>
        <video ref="videoPlayer" id="main-video" v-show="videoEnd" src="@/assets/images/main/main-video.mp4" autoplay muted playsinline controls></video>
    </div>
     <!-- src="@/assets/images/main/main-video.mp4" autoplay muted playsinline controls -->
</template>

<script setup>
import { onMounted,onUnmounted,ref } from 'vue';
const firstShow = ref(false);
const secondShow = ref(false);
const videoEnd = ref(true);
const isMobile = ref(false);

isMobile.value = window.innerWidth >= 576 ? false : true;

function resizeWidth(){
    isMobile.value = window.innerWidth >= 576 ? false : true;
    console.log(isMobile.value);
    if(!(isMobile.value)){
        videoEnd.value = true;
        firstShow.value = false;
        secondShow.value = false;
        document.getElementById('main-video').addEventListener('ended', function() {
            videoEnd.value = false;
            setTimeout(() => {
                firstShow.value = true;
                setTimeout(() => {
                    firstShow.value = false;
                    secondShow.value = true;
                }, 2000); // 첫 번째 애니메이션이 사라지고 두 번째 애니메이션이 나타나는 시간 조정
            }, 1000); // 비디오가 끝난 후 첫 번째 애니메이션이 나타나는 시간 조정
        });
    }else{
        videoEnd.value = false;
        firstShow.value = true;
        secondShow.value = true;
    }
}

window.addEventListener('resize',resizeWidth)

onMounted(() => {
    resizeWidth()
});
onUnmounted(()=> {
    window.removeEventListener('resize',resizeWidth)
})
</script>
<style scoped>
.first-enter-active,
.first-leave-active {
  transition: opacity 0.5s ease;
}

.first-enter-from,
.first-leave-to {
  opacity: 0;
}
.second-enter-from {
    opacity: 0;
}
.second-enter-active {
    transition: opacity .5s ease;
}
</style>