import { createI18n } from 'vue-i18n';
import en from './en';
import ko from './ko';
import zh from './zh';
import ja from './ja';
const i18n = createI18n ({
    legacy:false,
    // locale: localStorage.getItem('lang') != null ? localStorage.getItem('lang') : 'ko',
    fallbackLocale: 'ko', // 대체언어
    messages: {
      en,
      ko,
      zh,
      ja
    }
})

export default i18n;