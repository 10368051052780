export default {
    about: {
        aboutText1: 'Ten Entertainment leads the market with creative and innovative content',
        aboutText2: 'We deliver emotions through various platforms, providing the public with new experiences',
        aboutText3: 'We open new horizons in entertainment through continuous challenges',
        aboutHead1: 'Your story will shine around the world',
        aboutText4: 'Ten Entertainment professionally handles all processes from content production to global distribution',
        aboutText5: 'We turn creative ideas into reality and provide platforms to communicate with the public through an extensive network',
        aboutText6: 'Idea Implementation and Production',
        aboutText7: 'Global Network Ownership',
        aboutText8: 'Content Distribution',
    },
    business: {
        section1: {
            businessHead1: 'Building Business Partnerships',
            businessHead2: 'Cultural and Content Partnerships',
            businessHead3: 'Business Collaboration',
            businessHead4: 'Market Development and Expansion',
            businessText1: 'Promote cooperation with global companies in Japan, Taiwan, Thailand, Australia, the United States, etc.',
            businessText2: 'Negotiate contracts for product or service, content supply, distribution agreements, joint business development agents',
            businessText3: 'Establish content partnerships with companies to create beneficial solutions',
            businessText4: 'Facilitate cooperation in various fields, provide synergy by leading business sharing',
            businessText5: 'Promote cooperation with local partners for new market development',
            businessText6: 'Support market entry through market research, marketing strategy development, and networking'
        },
        section2: {
            businessHead1: 'Content Planning and Development',
            businessHead2: 'Production',
            businessHead3: 'Distribution and Marketing',
            businessText1: 'Discover new ideas and plan and develop various content based on those ideas',
            businessText2: 'Plan and develop various genres such as web dramas, programs, web series, and etc.',
            businessText3: 'Have production professionals including directors, producers, scriptwriters, and cinematographers for script writing, filming, editing, etc.',
            businessText4: 'Support distribution and marketing activities to deliver produced content to the public',
            businessText5: 'Distribute through broadcasting and internet streaming platforms, conduct advertising and promotions'
        },
        section3: {
            businessHead1: 'Platform Planning and Development',
            businessHead2: 'Platform Operation',
            businessHead3: 'Communication and Interaction',
            businessHead4: 'Content Provision',
            businessText1: 'Plan and develop user friendly digital platforms',
            businessText2: 'Develop software and applications, design user interfaces and experiences, build databases',
            businessText3: 'Responsible for smooth platform operation including server and network management, security and data management, user support, and maintenance',
            businessText4: 'Promote communication and interaction among users',
            businessText5: 'Support platform activation through features like message boards, chat, comments, and sharing',
            businessText6: 'Supply content on various platforms through global alliances',
            businessText7: 'Enable the use of various forms of content such as videos, audio, games, e-commerce, etc.'
        },
        section4: {
            businessHead1: 'Operating MCN (Multi-Channel Network)',
            businessHead2: 'Entertainment',
            businessText1: 'Manage and promote the content of multiple creators or streamers and channels',
            businessText2: 'Support creators and channels as partners with dedicated content production, marketing, and advertising revenue management on platforms like YouTube',
            businessText3: 'Contribute to effective channel management and growth with creators',
            businessText4: 'Nurture models and hosts internally. Plan and produce content',
            businessText5: 'Distribute it through various platforms',
            businessText6: 'Include various forms of content such as videos, web dramas, music, games, etc.',
            businessText7: 'Promote this content and support interaction with viewers',
        },
    },
    partner: {
        section1: {
            partnerHead1: 'Ten Entertainment Partners',
            partnerText1: 'Many partners have chosen Ten Entertainment'
        },
        section2: {
            partnerHead1: 'Ten Entertainment Partners',
            partnerText1: 'Many Entertainers are with Ten Entertainment'
        },
    },
    contact: {
        contactHead1: 'Contact Us',
        partnerText1: 'Please inquire about any questions you may have',
        brand: 'Name (Brand)',
        name: 'Contact Person',
        number: 'Contact Number',
        email: 'Email',
        emailSelect: 'Enter Directly',
        contactText: 'Inquiry',
        checkRadio: 'Consent to the collection and use of personal information',
        contactButton: 'Submit Inquiry',
        emailSuccess: "Your email has been sent successfully",
        emailFail: "Failed to send email."
    },
    footer: {
        address: '13, Seolleung-ro 107-gil, Gangnam-gu, Seoul, Republic of Korea',
        email: 'E-Mail',
        businessNumber: 'Business Registration Number',
        copyright: 'ALL RIGHTS RESERVED'
    }
}