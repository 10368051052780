<template>
    <div class="bg footer">
        <div class="wrapper">
            <div class="contact-container">
                <div class="flex-column gap3">
                    <h1>Contact Us</h1>
                    <div class="flex-column gap1">
                        <h3>{{ $t('contact.contactHead1') }}</h3>
                        <p>{{ $t('contact.partnerText1') }}</p>
                    </div>
                </div>
                <form class="flex-column gap1" @submit.prevent="handleSubmit">
                    <div class="input-wrapper">
                        <p class="placeholder-text">{{ $t('contact.brand') }}<span class="color-accent">*</span></p>
                        <input type="text" class="custom-input" v-model="form.company" required>
                    </div>
                    <div class="input-wrapper">
                        <p class="placeholder-text">{{ $t('contact.name') }}<span class="color-accent">*</span></p>
                        <input type="text" class="custom-input" v-model="form.name" required />
                    </div>
                    <div class="input-wrapper">
                        <p class="placeholder-text number-placeholder">{{ $t('contact.number') }}<span
                                class="color-accent">*</span></p>
                        <div class="number-container flex">
                            <p class="placeholder-text"><span class="color-accent"></span></p>
                            <input type="text" class="custom-input" v-model="form.contact1" required
                                placeholder="010" />
                            <img src="@/assets/images/icon/hypen-icon.png" />
                            <p class="placeholder-text"><span class="color-accent"></span></p>
                            <input type="text" class="custom-input second" v-model="form.contact2" required
                                placeholder="1234" />
                            <img src="@/assets/images/icon/hypen-icon.png" />
                            <input type="text" class="custom-input" v-model="form.contact3" required
                                placeholder="5678" />
                        </div>
                    </div>
                    <div class="input-wrapper">
                        <p class="placeholder-text">{{ $t('contact.email') }}<span class="color-accent">*</span></p>
                        <div class="input-container flex">
                            <input type="text" class="custom-input email" required v-model="form.email" />
                            <div class="email-container flex">
                                <p class="placeholder-text"><span class="color-accent"></span></p>
                                <input type="text" class="custom-input" required v-model="selectedEmail" />
                                <select class="email-select" @change="setEmail($event)">
                                    <option value="self">{{ $t('contact.emailSelect') }}</option>
                                    <option>naver.com</option>
                                    <option>gmail.com</option>
                                    <option>daum.net</option>
                                    <option>hanmail.net</option>
                                    <option>nate.com</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="input-wrapper last-child">
                        <p class="placeholder-text">{{ $t('contact.contactText') }}<span class="color-accent">*</span>
                        </p>
                        <textarea type="text" class="custom-input" v-model="form.message" required></textarea>
                    </div>
                    <div class="flex submit-container">
                        <div class="flex">
                            <input type="radio" checked v-model="form.privacyAgreement" />
                            <label>{{ $t('contact.checkRadio') }}</label>
                        </div>
                        <button type="submit">{{ $t('contact.contactButton') }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import emailjs from "emailjs-com";

const { locale } = useI18n({ useScope: 'global' });
const { t } = useI18n()
const selectedEmail = ref('');
const form = ref({
    company: "",
    name: "",
    contact1: "",
    contact2: "",
    contact3: "",
    email: "",
    message: "",
    privacyAgreement: false,
});

watch(locale, (newVal) => {
    setTimeout(() => {
        resizeNumber(newVal), 500
    })
})

onMounted(() => {
    const customInput = document.querySelectorAll('.custom-input');
    const placeholder = document.querySelectorAll('.placeholder-text');

    customInput.forEach((input, index) => {
        input.addEventListener('focus', () => {
            if (placeholder[index] != undefined && index != 2) {
                placeholder[index].style.display = 'none'
            }
        });
    })
    customInput.forEach((input, index) => {
        input.addEventListener('blur', () => {
            if (input.value === '') {
                placeholder[index].style.display = 'block'
            }
        });
    })
})

function setEmail(event) {
    let selectedVal = event.target.value
    if (selectedVal != 'self') {
        selectedEmail.value = event.target.value
        document.querySelector('.email-container input').readOnly = true
    } else {
        selectedEmail.value = ''
        document.querySelector('.email-container input').readOnly = false
    }

}

const isMobile = ref(false);
onMounted(() => {
    window.innerWidth <= 576 ? isMobile.value = true : isMobile.value = false
    window.addEventListener('resize', checkSize)
    resizeNumber()
})
onUnmounted(() => {
    window.removeEventListener('resize', checkSize)
})

function checkSize() {
    window.innerWidth <= 576 ? isMobile.value = true : isMobile.value = false
    resizeNumber()
}

function resizeNumber() {
    let numberPlaceholder = document.querySelector('.number-placeholder').clientWidth
    let numberContainer = document.querySelector('.number-container')
    numberContainer.style.paddingLeft = `${numberPlaceholder}px`
}

function handleSubmit() {
    const serviceID = "service_k8tprb3";
    const templateID = "template_0o12mgn";
    const userID = "Ml8DlxUXgOZQnl7HS";

    const templateParams = {
        company: form.value.company,
        name: form.value.name,
        contact1: form.value.contact1,
        contact2: form.value.contact2,
        contact3: form.value.contact3,
        email: form.value.email,
        email2: selectedEmail.value,
        message: form.value.message,
    };

    emailjs.send(serviceID, templateID, templateParams, userID).then(
        (response) => {
            console.log("SUCCESS!", response.status, response.text);
            alert(t('contact.emailSuccess'));
            resetForm();
        },
        (error) => {
            console.error("FAILED...", error);
            alert(t('contact.emailFail'));
        }
    );
}

function resetForm() {
    form.value.company = "";
    form.value.name = "";
    form.value.contact1 = "";
    form.value.contact2 = "";
    form.value.contact3 = "";
    form.value.email = "";
    form.value.message = "";
    form.value.privacyAgreement = false;
}
</script>

<style lang="scss" scoped></style>